<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="活动信息">
        <icon-button slot="extra" icon="return" @click.native="goBack"/>

        <el-descriptions :column="3" class="margin-top">
          <el-descriptions-item>
            <template slot="label"> 活动名称</template>
            {{ dataList.activityName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 创建时间</template>
            {{ dataInfo.createTime || dataList.createTime }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 创建人</template>
            {{ dataList.creater }}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 活动套餐</template>
            {{ dataInfo.leaseName || dataList.leaseTitle }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 活动时间</template>
            <!-- {{ dateFormat(dataList.beginDate) }}~{{ dateFormat(dataList.endDate) }} -->
              {{ dataList.activityTime || dataList.activityTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 活动价格</template>
            {{ (parseFloat(dataList.discountPrice)).toFixed(2) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 状态</template>
            {{ dataList.status == 0 ? '未开始' : dataList.activityStatus === 1 ? '进行中' : dataList.activityStatus === 2 ? '已结束' : '手动关闭' }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 关闭人</template>
            {{ dataList.operatorName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 关闭时间</template>
            {{ dataList.operatorTime }}
          </el-descriptions-item> -->
        </el-descriptions>

      </easy-card>
    </div>

    <div class="content-box section second">
      <easy-card title="活动购买记录">
        <!-- <div class="op-box">
          <div class="op-content">
            共计<span class="total-size">{{ total }}</span>条信息
          </div>
        </div> -->

        <el-table
            v-loading="isLoading"
            :data="tableData"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="车辆" prop="plate">
            <template slot-scope="scope">
                            <div v-for="item,index in scope.row.plate" :key="index">
                                <Numplate :type="item.plateColor? item.plateColor:'02'">
                                    {{ item.plateNumber }}
                                </Numplate>
                            </div>
                        </template>
          </el-table-column>
          <el-table-column label="购买时间" prop="buyingTime">
          </el-table-column>
          <el-table-column label="生效时间" width="200">
            <template slot-scope="scope">
              {{ scope.row.startTime }}
            </template>
          </el-table-column>
          <el-table-column label="到期时间" width="200">
            <template slot-scope="scope">
              {{ scope.row.endTime }}
            </template>
          </el-table-column>
          <el-table-column label="购买数量" prop="buyCount">
          </el-table-column>
          <el-table-column label="订单金额" prop="orderAmount">
          </el-table-column>
          <el-table-column label="长租状态" prop="leaseStatus">
          </el-table-column>
        </el-table>

        <div class="pagination-box">
          <el-pagination
              :current-page="1"
              :page-size="pageSize"
              :page-sizes="[10, 15, 30, 50]"
              :total="total"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </easy-card>
    </div>
  </div>
</template>

<script>
import {dateFormat} from '@/utils/utils';
import Numplate from "@/components/Numplate";
import {
  getLeaseActivityInfo,
  getLeaseBuyRecordList
}from "@/api/lease"
export default {
  name: "activity_detail",
  components:{
    Numplate
  },
  data() {
    return {
      id: "", //接收上个页面传回来的id
      //查询到的活动详细信息
      dataList: {},
      dataInfo:{},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      isLoading: false,
      //查询到的活动购买记录列表
      tableData: [],
    }
  },
  methods: {
    //时间戳转换
    dateFormat(date, fmt = 'yyyy-mm-dd HH:MM:SS') {
      date = new Date();
      date = !isNaN(date) ? new Date(date) : date;
      return dateFormat(fmt, date);
    },
    //返回活动列表页面
    goBack() {
      this.$router.back();
    },
    //查询套餐活动详情
    goSearch() {
      getLeaseActivityInfo({id:this.$route.query.id}).then(res => {
        if (res && res.code === 30 && res.result) {
          this.dataList = res.returnObject
        }
      })
    },
    //查询套餐活动购买记录
    searchBuyRecord() {
      this.isLoading = true;
      getLeaseBuyRecordList({id:this.$route.query.id,pageNo:this.pageNo,pageSize:this.pageSize}).then(res => {
        if (res && res.code === 30 && res.result) {
          this.isLoading = false;
          this.total = res.returnObject.total;
          this.tableData = res.returnObject.records;
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.searchBuyRecord();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.searchBuyRecord();
    },
  },
  mounted() {
    this.dataInfo  = JSON.parse(sessionStorage.getItem("active"));
    this.id = this.$route.query.id
    this.goSearch();
    this.searchBuyRecord();
  },
};
</script>


<style lang="less" scoped>
.title {
  line-height: 32px;
}

.second {
  margin-top: 20px;
}
</style>
